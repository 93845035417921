const tabList = [{
  Calss: '三年二班',
  id: '1',
  ranking: '1',
  serial: '45485368',
  poeples: '30',
  present: '高老师'
}, {    
  Calss: '三年三班',
  id: '2',
  ranking: '1',
  serial: '45485368',
  poeples: '30',
  present: '高老师'
}, {
  Calss: '二年一班',
  id: '3',
  ranking: '1',
  serial: '45485368',
  poeples: '30',
  present: '高老师'
}, {
  Calss: '二年二班',
  id: '4',
  ranking: '1',
  serial: '45485368',
  poeples: '30',
  present: '高老师'
}, {
  Calss: '二年三班',
  id: '5',
  ranking: '1',
  serial: '45485368',
  poeples: '30',
  present: '暂无班主任'
}, {
  Calss: '五年二班',
  id: '6',
  ranking: '1',
  serial: '45485368',
  poeples: '30',
  present: '高老师'
}, {
  Calss: '六年一班',
  id: '7',
  ranking: '1',
  serial: '45485368',
  poeples: '30',
  present: '高老师'
}, {
  Calss: '六年二班',
  id: '8',
  ranking: '1',
  serial: '45485368',
  poeples: '30',
  present: '高老师'
}, {
  Calss: '六年三班',
  id: '9',
  ranking: '1',
  serial: '45485368',
  poeples: '30',
  present: '高老师'
}]

const teacherList = [{
  TeaName: '高晓松',
  id: '1',
  gender: '男',
  Class: '一年二班',
  Moblie: '15561211130',
  addTime: '2019-10-13'
}, {
  TeaName: '高晓松',
  id: '2',
  gender: '男',
  Class: '一年二班',
  Moblie: '15561211130',
  addTime: '2019-10-13'
}, {
  TeaName: '高晓松',
  id: '3',
  gender: '男',
  Class: '暂无班级',
  Moblie: '15561211130',
  addTime: '2019-10-13'
}, {
  TeaName: '高晓松',
  id: '4',
  gender: '男',
  Class: '一年二班',
  Moblie: '15561211130',
  addTime: '2019-10-13'
}, {
  TeaName: '高晓松',
  id: '5',
  gender: '男',
  Class: '暂无班级',
  Moblie: '15561211130',
  addTime: '2019-10-13'
}, {
  TeaName: '高晓松',
  id: '6',
  gender: '男',
  Class: '一年二班',
  Moblie: '15561211130',
  addTime: '2019-10-13'
}, {
  TeaName: '高晓松',
  id: '7',
  gender: '男',
  Class: '暂无班级',
  Moblie: '15561211130',
  addTime: '2019-10-13'
}, {
  TeaName: '高晓松',
  id: '8',
  gender: '男',
  Class: '一年二班',
  Moblie: '15561211130',
  addTime: '2019-10-13'
}, {
  TeaName: '高晓松',
  id: '9',
  gender: '男',
  Class: '一年二班',
  Moblie: '15561211130',
  addTime: '2019-10-13'
}]

const StuList = [{
  StuName: '沈伈',
  gender: '女',
  id: '1',
  addTime: '2019-10-13',
  lastTime: '2020-10-13'
}, {
  StuName: '沈伈',
  gender: '女',
  id: '2',
  addTime: '2019-10-13',
  lastTime: '2020-10-13'
}, {
  StuName: '沈伈',
  gender: '女',
  id: '3',
  addTime: '2019-10-13',
  lastTime: '2020-10-13'
}, {
  StuName: '沈伈',
  gender: '女',
  id: '4',
  addTime: '2019-10-13',
  lastTime: '2020-10-13'
}, {
  StuName: '沈伈',
  gender: '女',
  id: '5',
  addTime: '2019-10-13',
  lastTime: '2020-10-13'
}, {
  StuName: '沈伈',
  gender: '女',
  id: '6',
  addTime: '2019-10-13',
  lastTime: '2020-10-13'
}, {
  StuName: '沈伈',
  gender: '女',
  id: '7',
  addTime: '2019-10-13',
  lastTime: '2020-10-13'
}, {
  StuName: '沈伈',
  gender: '女',
  id: '8',
  addTime: '2019-10-13',
  lastTime: '2020-10-13'
}, {
  StuName: '沈伈',
  gender: '女',
  id: '9',
  addTime: '2019-10-13',
  lastTime: '2020-10-13'
}]
const applyList = [{
  name: '沈伈',
  gender: '女',
  id: '1',
  addTime: '2019-10-13',
  transfer: '七十二中学'
}, {
  name: '沈伈',
  gender: '女',
  id: '2',
  addTime: '2019-10-13',
  transfer: '七十二中学'
}, {
  name: '沈伈',
  gender: '女',
  id: '3',
  addTime: '2019-10-13',
  transfer: '七十二中学'
}, {
  name: '沈伈',
  gender: '女',
  id: '4',
  addTime: '2019-10-13',
  transfer: '七十二中学'
}, {
  name: '沈伈',
  gender: '女',
  id: '5',
  addTime: '2019-10-13',
  transfer: '七十二中学'
}, {
  name: '沈伈',
  gender: '女',
  id: '6',
  addTime: '2019-10-13',
  transfer: '七十二中学'
}, {
  name: '沈伈',
  gender: '女',
  id: '7',
  addTime: '2019-10-13',
  transfer: '七十二中学'
}, {
  name: '沈伈',
  gender: '女',
  id: '8',
  addTime: '2019-10-13',
  transfer: '七十二中学'
}, {
  name: '沈伈',
  gender: '女',
  id: '9',
  addTime: '2019-10-13',
  transfer: '七十二中学'
}]

const SchooldataList = [{
  Calss: '三年二班',
  id: '1',
  ranking: '3',
  poeples: '35',
  present: '王老师',
  progress: 95.7
}, {
  Calss: '三年二班',
  id: '2',
  ranking: '3',
  poeples: '35',
  present: '王老师',
  progress: 95.7
}, {
  Calss: '三年二班',
  id: '3',
  ranking: '6',
  poeples: '35',
  present: '王老师',
  progress: 95.7
}, {
  Calss: '三年二班',
  id: '4',
  ranking: '1',
  poeples: '35',
  present: '王老师',
  progress: 90.7
}, {
  Calss: '三年二班',
  id: '5',
  ranking: '7',
  poeples: '35',
  present: '王老师',
  progress: 93
}, {
  Calss: '三年二班',
  id: '6',
  ranking: '1',
  poeples: '35',
  present: '王老师',
  progress: 95.7
}, {
  Calss: '三年二班',
  id: '7',
  ranking: '8',
  poeples: '35',
  present: '王老师',
  progress: 95.3
}, {
  Calss: '三年二班',
  id: '8',
  ranking: '1',
  poeples: '35',
  present: '王老师',
  progress: 80.7
}, {
  Calss: '三年二班',
  id: '9',
  ranking: '1',
  poeples: '35',
  present: '王老师',
  progress: 66.9
}]

const TeaInfoList = [{
  SchName: '大连市第七十二中学',
  id: '1',
  Class: '三年二班-五年二班',
  serial: '252123687',
  StartTime: '2019-10-28 23:12:00',
  EndTime: '2020-10-31 23:12:00'
}, {
  SchName: '大连市第七十二中学',
  id: '2',
  Class: '三年二班-五年二班',
  serial: '252123687',
  StartTime: '2019-10-28 23:12:00',
  EndTime: '2020-10-31 23:12:00'
}, {
  SchName: '大连市第七十二中学',
  id: '3',
  Class: '三年二班-五年二班',
  serial: '252123687',
  StartTime: '2019-10-28 23:12:00',
  EndTime: '2020-10-31 23:12:00'
}, {
  SchName: '大连市第七十二中学',
  id: '4',
  Class: '三年二班-五年二班',
  serial: '252123687',
  StartTime: '2019-10-28 23:12:00',
  EndTime: '2020-10-31 23:12:00'
}, {
  SchName: '大连市第七十二中学',
  id: '5',
  Class: '三年二班-五年二班',
  serial: '252123687',
  StartTime: '2019-10-28 23:12:00',
  EndTime: '2020-10-31 23:12:00'
}, {
  SchName: '大连市第七十二中学',
  id: '6',
  Class: '三年二班-五年二班',
  serial: '252123687',
  StartTime: '2019-10-28 23:12:00',
  EndTime: '2020-10-31 23:12:00'
}, {
  SchName: '大连市第七十二中学',
  id: '7',
  Class: '三年二班-五年二班',
  serial: '252123687',
  StartTime: '2019-10-28 23:12:00',
  EndTime: '2020-10-31 23:12:00'
}, {
  SchName: '大连市第七十二中学',
  id: '8',
  Class: '三年二班-五年二班',
  serial: '252123687',
  StartTime: '2019-10-28 23:12:00',
  EndTime: '2020-10-31 23:12:00'
}, {
  SchName: '大连市第七十二中学',
  id: '9',
  Class: '三年二班-五年二班',
  serial: '252123687',
  StartTime: '2019-10-28 23:12:00',
  EndTime: '2020-10-31 23:12:00'
}]
export default {
  tabList,
  teacherList,
  StuList,
  applyList,
  SchooldataList,
  TeaInfoList
}
