<template>
  <div id="body">
    <div class="content">
      <div class="teacherInfo">
        <div class="photos">
          <img src="../../assets/APP_logo.png" alt="" />
          <p>王老师</p>
          <p>海纳白川，有容乃大</p>
        </div>
        <div class="brief">
          <p><i class="el-icon-setting"></i>初中教师</p>
          <p>
            <i class="el-icon-setting">大连市第七十二中学-高一三班-班主任</i>
          </p>
          <p><i class="el-icon-setting">辽宁省大连市</i></p>
        </div>
      </div>
      <div class="fatherBox">
          <div class="headerBox">
        <p>历史记录</p>
      </div>

      <el-table :data="ClassInfos" class="tables">
      <el-table-column prop="serial" label="班级编号" width="200"></el-table-column>
      <el-table-column prop="SchName" label="所在学校"  width="260"> </el-table-column>
      <el-table-column prop="Class" label="任教阶段" width="240">
      </el-table-column>
      <el-table-column label="任教时间" sortable width="280">
          <template slot-scope="scope">
            <!-- <i class="el-icon-time"></i> -->
            <div style="display:flex;align-items:center">
                <img src="../../assets/icon_start.png" alt="" class="icon">
                <span style="margin-left: 10px;font-size:15px">{{ scope.row.StartTime }}</span>
            </div>
            <!-- <br/> -->
            <!-- <i class="el-icon-time"></i> -->
           <div style="display:flex;align-items:center">
                <img src="../../assets/icon_final.png" alt="" class="icon">
                <span style="margin-left: 10px;font-size:15px">{{ scope.row.EndTime }}</span>
           </div>
      </template>
       <!-- <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ scope.row.EndTime }}</span>
      </template> -->
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="230">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="detail(scope.$index, scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import Json from '../../../mock.js'
export default {
  data () {
    return {
      ClassInfos: []
    }
  },
  mounted () {
    this.ClassInfos = Json.TeaInfoList
  },
  methods: {
    detail (index, row) { // 点击详情
      // console.log(row)
      this.$router.push({ name: 'centerDetail' })
    }
  }
}
</script>

<style lang="scss" scoped>
#body {
  // height: 100%;
  overflow: hidden;
  background: #f0f2f5;
}
.content {
  width: 98%;
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  margin-top: 32px;
  .teacherInfo {
      width: 20%;
    //   width: 350px;
      height: 363px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 24px;
    justify-content: center;
    background: white;
    img {
      width: 106px;
      height: 106px;
      border-radius: 50%;
      background: yellowgreen;
    }
    .photos{
        p:nth-child(2){
            font-size: 20px;
            font-family: PingFangSC-Medium;
            color: rgba(0, 0, 0, 0.85);
        }
        p:nth-child(3){
            font-size: 14px;
            font-family: PingFangSC-Regular;
            color: rgba(0, 0, 0, 0.65);
        }
    }
    .brief{
        p{
            text-align: left;
            font-size: 14px;
            font-family: PingFangSC-Regular;
            color: rgba(0, 0, 0, 0.65);
        }
    }
  }
}

.fatherBox {
  width: 75%;
  display: flex;
  background: white;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  .headerBox {
    display: flex;
    height: 52px;
    width: 98%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #e9e9e9;
    p {
      font-size: 16px;
      font-family: 萍方-简;
      font-weight: normal;
      color: #000000;
      margin-left: 32px;
    }
  }
}
</style>
